// import the library
import { library } from "@fortawesome/fontawesome-svg-core"

// import your icons
import {
  faCode,
  faHighlighter,
  faStar,
  faHistory,
  faFutbol,
  faEyeSlash,
  faLink,
  faAngleDoubleRight,
  faPlay,
  faAngleRight,
  faShoppingCart,
  faSignIn,
  faBars,
  faEnvelope,
  faAngleDown,
  faArrowsAlt,
  faExternalLinkSquare,
  faMapMarker,
  faPhone,
  faCalendarCheck,
  faPowerOff,
} from "@fortawesome/free-solid-svg-icons"

// social icons
import {
  fab,
  faTwitter,
  faFacebook,
  faGooglePlus,
  faPinterest,
  faLinkedin,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons"

library.add(
  faCode,
  faHighlighter,
  faStar,
  faHistory,
  faFutbol,
  faEyeSlash,
  faLink,
  faAngleDoubleRight,

  fab,
  faTwitter,
  faFacebook,
  faGooglePlus,
  faPinterest,
  faLinkedin,
  faAngleRight,
  faShoppingCart,
  faSignIn,
  faBars,
  faEnvelope,
  faAngleDown,
  faArrowsAlt,
  faExternalLinkSquare,
  faPlay,
  fab,
  faTwitter,
  faFacebook,
  faGooglePlus,
  faPinterest,
  faAngleRight,
  faMapMarker,
  faPhone,
  faCalendarCheck,
  faInstagram,
  faPowerOff

  // more icons go here
)
